import React from 'react';
import { Link } from 'react-router-dom';

const Posts = props => {
  const { articles } = props;
  
  return (
    <div>
      {articles.map((article) => (
        <div className="row" key={article.id}>
          <div className="col-md-7">
            <h2 className="posts-heading text-danger">
              <Link to={`/blog/${article.slug}`} className="text-decoration-none text-danger"> {article.title} </Link>
            </h2>
            <h5 className="posts-meta text-muted">{new Date(article.published_at).toLocaleDateString(undefined, {month: "long", day: "numeric", year: "numeric"})}</h5>
            <p className="lead posts-summary">{article.description}</p>
          </div>
        </div>
      ))}
    </div>
  );
}

export default Posts;

import React from "react";

const About = () => {
  return (
    <div>
      <div className="container pt-2 pb-2">
        <h1 className="display-4">Resume</h1>
        <hr></hr>
        <p className="lead">
          I am a Fullstack Engineer with experience designing and building
          scalable frontend and backend apps in production. My main stack is
          JavaScript with Node.js (backend), React (frontend) and React Native
          (mobile).
        </p>
        <p className="lead">
          I also do some devops including setup and deployment of systems and
          servers on PaaS and IaaS platforms like GCP, DigitalOcean, Heroku,
          etc.
        </p>

        <div className="about-content">
          <div className="about-section">
            <h3 className="">
              <span className="fa fa-circle about-section-bullet"></span>{" "}
              Experience
            </h3>
            <div className="experience">
              <div className="experience-header mt-3">
                <h5>
                  <span className="">Senior Frontend Engineer</span> —{" "}
                  <span className=" font-italic">peaq GmbH</span> —{" "}
                  <span className="text-muted">February 2022 - May 2022</span>
                </h5>
              </div>
              <div className="experience-detail mb-4">
                <p>
                  peaq is a web3 startup that is leveraging the Blockchain to
                  enable human-machine interactions that will form the basis for
                  the Economy of Things.
                </p>
                <p>
                  At peaq I built a blockchain explorer that simplified the
                  display of data from a substrate blockchain network. It
                  leveraged a data indexing framework on which I built a
                  supporting processor for searching, filtering and pagination
                  of historical chain data including blocks, events, transfers,
                  etc.
                </p>
              </div>

              <div className="experience-header mt-3">
                <h5>
                  <span className="">Lead Fullstack Engineer</span> —{" "}
                  <span className=" font-italic">
                    Enterfive Company Limited
                  </span>{" "}
                  —{" "}
                  <span className="text-muted">
                    September 2019 - January 2022
                  </span>
                </h5>
              </div>
              <div className="experience-detail mb-4">
                <p>
                  Enterfive is a digital solutions company that helps businesses
                  to ideate, sketch, build and market product solutions relevant
                  to any industry across the board and borders. With Versus we
                  create, consult and track digital campaign solutions using
                  well-distilled research findings to drive optimal results.
                  Tixy, our other product helps organisers own their events
                  management process by providing automated ticketing sales,
                  guestlist management and insights.
                </p>
                <p>At Enterfive I</p>
                <ul>
                  <li>
                    Coordinated the implementation of backend (Node) and
                    frontend (React) infrastructure for Versus 2.0 - our
                    flagship product that combines online and offline consumer
                    data to give brands actionable and competitive insight for
                    the African market
                  </li>
                  <li>
                    Led architecture refactoring of the Tixy events management
                    mobile apps (Android and PWA) to reduce check-in (scanning)
                    time by more than 50% on the average
                  </li>
                  <li>
                    Refactored Tixy check-in PWA (Vue.js) to become more
                    efficient by processing (scanning in) 2 attendees per
                    second, 16x better than the previous 1 person in 8 seconds.
                  </li>
                  <li>
                    Built a Strangler Facade with a suite of Cloud Functions
                    (Node.js) on top of Tixy’s legacy PHP backend to provide
                    modern REST APIs for consumption by the Android app and PWA.
                  </li>
                  <li>
                    Automated CI/CD processes for our frontend and backend
                    products to improve development, testing and deployment
                    cycles and make devs work easier.
                  </li>
                  <li>
                    Mentored other engineers on how best to approach problems,
                    best practices and architecture decisions.
                  </li>
                </ul>
              </div>

              <div className="experience-header mt-3">
                <h5>
                  <span className="">CTO</span> —{" "}
                  <span className=" font-italic">Aidahbot Company Limited</span>{" "}
                  —{" "}
                  <span className="text-muted">August 2017 - August 2019</span>
                </h5>
              </div>
              <div className="experience-detail mb-4">
                <p>
                  Aidahbot is a customer service platform for businesses with
                  high volume customer messaging. We leverage AI to help such
                  businesses scale their support teams and continuously engage
                  their customers 24/7 on several digital channels.
                </p>
                <p>
                  I led the tech team to architect, build and maintain a variety
                  of platforms for the company's business use cases including:
                </p>
                <ul>
                  <li>Backend API server using Node.js and hosted on Heroku</li>
                  <li>
                    Livechat server using Node.js and hosted on a Virtual
                    Private Server (VPS) on Digital Ocean
                  </li>
                  <li>
                    Email microservice using Node.js and hosted on Google Cloud
                    Platform (GCP) - Cloud Functions
                  </li>
                  <li>
                    Messenger Bot server using Node.js and hosted on Heroku
                  </li>
                  <li>MongoDb Database hosted on Mlab</li>
                  <li>
                    Frontend Dashboards including customer service and admin
                    dashboards using React and hosted on Netlify and Firebase
                    Hosting
                  </li>
                  <li>
                    Self-hosted Version Control using Gitlab Community Edition
                    (CE) with shared Runners for Continuous Integration (CI)
                    across multiple projects, all hosted on a VPS on
                    DigitalOcean.
                  </li>
                </ul>
              </div>

              <div className="experience-header mt-3">
                <h5>
                  <span className="">EIT</span> —{" "}
                  <span className=" font-italic">
                    Meltwater Entrepreneurial School of Technology (MEST)
                  </span>{" "}
                  —{" "}
                  <span className="text-muted">August 2016 - August 2017</span>
                </h5>
              </div>
              <div className="experience-detail mb-4">
                <p>
                  MEST is a Pan-African training programme, seed fund and
                  incubator that nurtures young entrepreneurs in Technical,
                  Business and Communication skills to enable them build
                  software companies that solve problems for local and global
                  markets.
                </p>
                <p>
                  As an Entrepreneur-in-Training (EIT), I worked on several
                  Capstone projects with different teams including:
                </p>
                <ul>
                  <li>
                    Messenger assistant (Node.js, Messenger APIs) that orders
                    pizza, pays utility bills and recharges phones of users
                  </li>
                  <li>
                    Electronic checkout prototype (React Native) that aimed to
                    remove checkout queues in busy stores and malls
                  </li>
                  <li>
                    Money transfer app (React Native) that enabled cheap US, UK
                    settlements to Ghanaian and Nigerian banks.
                  </li>
                </ul>
              </div>

              <div className="experience-header mt-3">
                <h5>
                  <span className="">Intern</span> —{" "}
                  <span className=" font-italic">DevLess</span> —{" "}
                  <span className="text-muted">December 2016 - May 2017</span>
                </h5>
              </div>
              <div className="experience-detail mb-4">
                <p>
                  DevLess provides pluggable backend and data storage services
                  to developers allowing them to easily build apps and
                  interfaces without writing backend code. They are also a big
                  Open Source Software (OSS) contributor as their core platform
                  and libraries are open source.
                </p>
                <p>
                  As a developer intern, I honed my JavaScript skills writing
                  open source Node.js libraries for integration into the core
                  PHP application.
                </p>
                <ul>
                  <li>
                    I built and published a library that exposed DevLess APIs in
                    a Node environment for server side applications
                  </li>
                  <li>
                    I wrote and maintained the script that automates DevLess
                    deployments on a Linux environment to take away the hassles
                    developers encountered during installation and configuration
                  </li>
                  <li>
                    I wrote technical documentation and tutorials on different
                    use cases of the DevLess APIs.
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="about-section">
            <h3>
              <span className="fa fa-circle about-section-bullet"></span>{" "}
              Education
            </h3>
            <div className="education">
              <div className="education-header mt-3">
                <h5>
                  <span className="">B.Eng(Elect/Elect Engr)</span> —{" "}
                  <span className=" font-italic">University of Uyo</span> —{" "}
                  <span className="text-muted">January 2010 - July 2015</span>
                </h5>
              </div>
              <div className="education-detail mb-4">
                <p>
                  I studied Electrical/Electronics Engineering at the University
                  of Uyo where I specialized in Communications Engineering.
                </p>
                <p>
                  For my{" "}
                  <a
                    href="https://drive.google.com/open?id=1mnKcwbnrn9DOC4xux52HASn5KtPxwmyI"
                    className="text-decoration-none"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    thesis
                  </a>
                  , I looked at how we could reduce energy wastages arising from
                  poor lighting usage practices. To demonstrate the results I
                  designed and implemented a smart lighting system that
                  monitored human presence and occupancy in a room, measured
                  intensity of daylight available and compensated intensity with
                  light from an energy efficient and cost-effective source. It
                  also provided an alternative power supply for the system in
                  the event of failure from grid supply.
                </p>
              </div>
            </div>
          </div>

          <div className="about-section">
            <h3>
              <span className="fa fa-circle about-section-bullet"></span>{" "}
              Volunteering
            </h3>
            <div className="volunteering">
              <div className="volunteering-header mt-3">
                <h5>
                  <span className="">Lead</span> —{" "}
                  <span className=" font-italic">
                    Developer Circles from Facebook, Accra
                  </span>{" "}
                  —{" "}
                  <span className="text-muted">October 2017 - August 2019</span>
                </h5>
              </div>
              <div className="volunteering-detail mb-4">
                <p>
                  Developer Circles from Facebook is a Facebook supported
                  community of humans interested in technology who come together
                  in local meetups and collaborate in Facebook Groups to share
                  and learn about latest technologies including Facebook's.
                </p>
                <p>
                  As one of the pioneer Leads for Accra circle I contributed to
                  the growth of Accra's tech ecosystem and added value to young
                  lives by moderating online engagements and organizing regular
                  offline meetups, workshops and hackathons around new and
                  emerging tech. This service took me to several conferences and
                  workshops including F8 - Facebook's annual Developer
                  Conference in San Francisco - where I met some of the best
                  minds in tech, networked, shared challenges and brought back
                  ideas that helped solve some of the individual and general
                  challenges members faced.
                </p>
              </div>
              <div className="volunteering-header mt-3">
                <h5>
                  <span className="">Workshops & Talks</span>
                </h5>
              </div>
              <div className="volunteering-detail mb-4">
                <p className="">
                  I have delivered technical workshops at meetups and
                  conferences including
                </p>
                <ul>
                  <li>
                    World Bot Meetup #3 @ Slack —{" "}
                    <span className="text-muted">May 2, 2019</span>
                  </li>
                  <p>
                    <a
                      href="https://docs.google.com/presentation/d/e/2PACX-1vT7EwTyO68rGGHgiQhfGq5eUP8Z0SEdszxiZ8xn0oidcrMsLLjeHjo400U9_mYaCgoyBcot1RIDIy6w/pub"
                      className="text-decoration-none"
                      aria-label="One of my talks"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Chatbots in Ghana: The journey so far
                    </a>
                  </p>

                  <li>
                    Developers and Startups Summit 2018 —{" "}
                    <span className="text-muted">December 8, 2018</span>
                  </li>
                  <p>
                    <a
                      href="https://docs.google.com/presentation/d/e/2PACX-1vTSVh-dVEyGSwZ-qSBalYThL-VXWNzVpSmztN3L7ctEjp8JK-RrKX7lgU_XtXSryXIoi81mTz7v-6-a/pub"
                      className="text-decoration-none"
                      aria-label="One of my talks"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Payments for Messenger: Custom checkout flows for African
                      Businesses
                    </a>
                  </p>

                  <li>
                    GDG DevFest Accra 2018 —{" "}
                    <span className="text-muted">November 24, 2018</span>
                  </li>
                  <p>
                    <a
                      href="https://docs.google.com/presentation/d/e/2PACX-1vT4FOw1o_Qzl46hNYK726kntni-Ws9txGs7f2v5MGxFkdaTyxy2uZgwLsR1R9PH116QWm4lsvdgjKFa/pub"
                      className="text-decoration-none"
                      aria-label="One of my talks"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Serverless Microservices with Cloud Functions
                    </a>
                  </p>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <footer className="footer mt-auto py-3 bg-dark">
        <div className="container">
          <div className="d-flex justify-content-between">
            <a
              href="/"
              className="text-decoration-none"
              aria-label="Back to home page"
            >
              <span className="fa fa-chevron-left text-muted"></span>
              <span className="text-muted"> Home</span>
            </a>
            {/* <a href="/" className="text-decoration-none"><span className="text-muted">Blog </span><span className="fa fa-chevron-right text-muted"></span></a> */}
          </div>
          {/* <div className="d-flex flex-row-reverse">
            <a href="/" className="text-decoration-none"><span className="text-muted">Blog </span><span className="fa fa-chevron-left text-muted"></span></a>
          </div> */}
        </div>
      </footer>
    </div>
  );
};

export default About;

import React from "react";
import prevAidah from "../img/project_previews/aidah.png";
import prevVersus from "../img/project_previews/versus.png";
import prevKegow from "../img/project_previews/kegow.png";

const Projects = () => {
  return (
    <div className="container pt-2 pb-2">
      <h1 className="display-4">Projects</h1>
      <hr></hr>
      <p className="lead">
        These are some of the publicly available projects I've built the
        frontend and/or backend
      </p>
      <div className="">
        <div className="row">
          <div className="project col-sm-4 mb-5">
            <a
              href="https://aidah.chat"
              target="_blank"
              rel="noopener noreferrer"
              className="text-decoration-none text-reset"
            >
              <img className="" src={prevAidah} alt="Aidah website preview" />
              <h5 className="text-center mt-2">Aidah</h5>
              <p className="text-center">
                Multi-channel customer messaging app for businesses.{" "}
              </p>
              <p className="text-center font-weight-bold">
                Node.js, Express, MongoDB Backend
              </p>
            </a>
          </div>
          <div className="project col-sm-4 mb-5">
            <a
              href="https://versus.africa/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-decoration-none text-reset"
            >
              <img className="" src={prevVersus} alt="Versus app preview" />
              <h5 className="text-center mt-2">Versus</h5>
              <p className="text-center">
                A simple way for brands to access very pure research on markets
                across Africa.
              </p>
              <p className="text-center font-weight-bold">
                React, Node.js, Python, PostgreSQL
              </p>
            </a>
          </div>
          <div className="project col-sm-4 mb-5">
            <a
              href="https://app.kegow.com/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-decoration-none text-reset"
            >
              <img className="" src={prevKegow} alt="Kegow app preview" />
              <h5 className="text-center mt-2">Kegow</h5>
              <p className="text-center">
                Mobile money wallet for the unbanked. Enables bill payments and
                money transfers with just a phone number
              </p>
              <p className="text-center font-weight-bold">
                React, Node.js, MYSQL
              </p>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Projects;

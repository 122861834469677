import React from 'react';
import { Link } from 'react-router-dom';

const Home = () => {
  return (
    <div className="container-fluid bg-dark d-flex align-items-center justify-content-center" style={{height: '100vh'}}>
      <div className="text-center">
        <p className="display-4 text-light">Hello, I'm <span className="text-danger" >John Otu,</span></p>
        <p className="display-4 text-white">a fullstack software engineer</p>
        <p className="lead text-white">My main stack is JavaScript, with React on the frontend and Node.js on the backend</p>
        <Link to="/about" ><button className="btn btn-lg btn-outline-light intro-btn mr-2">More about me</button></Link>
        <Link to="/projects" ><button className="btn btn-lg btn-outline-light intro-btn ml-2">Some of my work</button></Link>
        {/* <Link to="/blog" ><button className="btn btn-lg btn-outline-light intro-btn ml-2">Where I write</button></Link> */}
        <div className="mt-4">
          <a href="https://github.com/johnotu" className="btn btn-outline-light intro-btn mr-2" aria-label="My Github" target="_blank" rel="noopener noreferrer"><span className="fa fa-github"></span></a>
          <a href="https://twitter.com/john_otu" className="btn btn-outline-light intro-btn mr-2" aria-label="Tweet at me" target="_blank" rel="noopener noreferrer"><span className="fa fa-twitter"></span></a>
          <a href="https://www.linkedin.com/in/johnotu/" className="btn btn-outline-light intro-btn mr-2" aria-label="Check out my LinkedIn profile" target="_blank" rel="noopener noreferrer"><span className="fa fa-linkedin"></span></a>
          <a href="mailto:hello@johnotu.com?Subject=Hello%20John" className="btn btn-outline-light intro-btn mr-2" aria-label="Send me an email"><span className="fa fa-envelope"></span></a>
          
        </div>
      </div>
      
    </div>
  );
}

export default Home;
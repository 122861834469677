import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import Home from './components/Home';
import About from './components/About';
import Projects from './components/Projects';
import Blog from './components/Blog';

const App = () => {
  return (
    <Router>
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/about" exact component={About} />
        <Route path="/projects" exact component={Projects} />
        <Route path="/blog" exact >
          <Blog scope="posts" />
        </Route>
        <Route path="/blog/:slug" >
          <Blog scope="post" />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
import React from 'react';

const Post = props => {
  const { article } = props;

  return (
    <div>
      <div className="row">
        <div className="col-md-8">
          <h1 className="post-heading">{article.title}</h1>
          <h5 className="post-meta text-muted">
            {new Date(article.published_at).toLocaleDateString(undefined, {
              month: "long",
              day: "numeric",
              year: "numeric",
            })}
          </h5>
          <div className="post-body" dangerouslySetInnerHTML={{ __html: article.body_html }}></div>
        </div>
      </div>
    </div>
  );
}

export default Post;
